@media (max-width: 1050px){
    .sale-section{
        width: 70%;
    }


}


@media (max-width: 988px){

    .feature-img{
        width: 100%;
    }
    .how-it-work-img{
        width: 100%;
    }
    .feature-ul li a{
        width: 100%;
    }
    .btn-group-custom .btn-custom:nth-child(1) {
        margin-right: 2rem;
    }
    .ido-box{

        margin-bottom: 30px;
    
    }
    .hci-iframe{
        width: 100%;
        height: 200px;
    }

    .custom-table{

        margin-bottom: 40px;

    }
    .banner-video video{
        width: 100%;
    }
    .manager-dropdown .dropdown-menu{
        border: none !important;
        background-color: var(--primary) !important;
    }
    .home-section-iframe{
        height: 400px;
        width: 100%;
    }
    .sale-section{
        width: 90%;
        
    }

}
@media (max-width: 339px){
    .image-card img {
        width: 140px;
        height: 156px;
    }
    .image-card {
        width: 140px;
    }
    .btn-custom {
        padding: 10px 25px !important;
    }
    .btn-group-custom .btn-custom:nth-child(1) {
        margin-right: 1rem;
        margin-bottom: 0px !important;
    }    
}
@media (max-width: 767px){
    .banner-video-section video{
        width: 100%;
    }
    .h2{
        font-size: 2.1rem !important;
    }
    .footer h2{
        font-size: 30px;
    }
    .footer-ul a{
        font-size: 18px !important;
        line-height: 30px;
    }
    .home-section-iframe {
        height: 225px;
        width: 100%;
    }
    .logo-text{
        margin-top: 1rem !important;
    }
    .banner-section{
        margin-bottom: 5rem;
    }
    .top_div{
        display:flex;
        justify-content:center
    }

    .banner-img{

        margin-top: 20px;
        
    }
    .apply-btn-section .btn-custom {
        padding: 0.5rem 2rem !important;
        width: 278px;
        margin: auto;
    }

    .apply-btn-section {
        display: flex;
        flex-direction: column;
    }
    .apply-btn-section .btn-custom{
        margin-top: 2rem;
    }
    .footer-right-text{
        justify-content: left;
        margin-top: 2rem;
    }
    .sroll-top{
        margin-bottom: 1rem;
    }
    .feature{
        margin-top: 1rem;
    }
    .feature-section{
        padding-top: 1rem !important;
    }
    .feature-ul li a {
        width: 276px;
        font-size: 23px;
        margin: auto;
    }
    .navbar-toggler {
        font-size: 1rem !important;
    }
    .h2{
        line-height: 45px !important;
    }
    .ul-between-flex h5, .ul-between-footer h5 {
        font-size: 18px !important;
    }
    .ul-box-between {
        padding: 2rem 1rem !important;
    }
    .ul-between-footer {
        margin-top: 3.5rem !important;
    }
        
    .tier-table-section th{
        padding: 0.5rem !important;
        font-size: 14px;
        white-space: nowrap;
    }
    .tier-table-section td {
        font-size: 15px;
    }
    .tier-table-section {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
    .non-crypto-text{
        font-size: 1.1rem;
    }
    .how-it-text {
        font-size: 1.1rem;
    }
    .banner-p {
        font-size: 1.2rem;
    }
    .zpad-tier-text {
        text-align: left;
        font-size: 15px;
    }

.ido-box{

    margin-bottom: 30px;

}
.video-section{
    width: 100% !important;
}
.video-main-section{
    width: 100%;
}
.banner-section{
    height: 1000px;
    align-items: flex-start !important;
}

.banner-section-m{

height: auto !important;

}


.banner-background{
    height: 100%;
}
.custom-order-4{
    order: 4;
}
.box-ul-column {
    flex-direction: inherit;
    flex-wrap: wrap;
}
.box-ul-column .ul-box {
    width: 157px !important;
    height: 120px !important;
}
.box-ul-flex .ul-box:not(:last-child) {
    margin-right: 0rem;
}
.box-ul-flex {
    flex-wrap: wrap;
}
.box-ul-flex .ul-box {
    width: 157px !important;
    height: 120px !important;
    margin: 1rem auto !important; 

}
.z-pad-section {
    padding-top: 4rem !important;
}

.staking-dropdown{
    
    text-align: center;
    margin: 3rem 0;

}

.ido-box .btn-group-custom{

    flex-wrap: wrap;
    justify-content: center !important;

}

.ido-box .btn-group-custom a{

margin-bottom: 10px !important;

}

.flex-xs-wrap{

    flex-wrap: wrap;

}

.conditions{

    margin-right: 0 !important;
    margin-bottom: 30px;
    width: 100%;

}

.roadmap {
    flex-direction: column;
    margin: 50px 14em 50px 0;

  }

  .roadmap-circle {
    width: 50px;
    height: 50px;
  }

  .roadmap-hr {
    width: 1px;
    height: 150px !important;
    display: inline-block;
  
    opacity: 1 !important;
  }

  .roadmap-item p {
    left: 120%;
    font-size: 0.9em;
    width: 225px;
    top: auto;
    text-align: left;
  }

  .custom-checkbox{

    font-size: 12px;

  }

  .custom-checkbox input {
    width: 10px;
    height: 10px;
    margin-right: 5px !important;
}

}

@media (max-width: 420px){


    .btn-group-custom{
        /* flex-direction: column; */
        text-align: left;
        align-items: flex-start;
    }

    .btn-group-custom .btn-custom:nth-child(1) {
        margin-bottom: 1rem;
    }

    .btn-custom{

        padding: 10px 20px;

    }

    .ido-cap{
        flex-direction: column;
        text-align: center !important;

    }

    .ido-cap span{

        margin:10px 0;
        text-align: center !important;
        

    }

    .ido img{

        width: 45px;

    }
    .logo-text-img{
        width: 270px !important;
    }
    .banner-video-section video{
        width: 100%;

    }
    

}
@media (max-width:991px) and (min-width:768px) {

    .feature-img {
        width: 100%;
    }
    
    .how-it-work-img{
        width: 100%;
    }

    .btn-group-custom .btn-custom{
        padding: 10px 21px;

    }
    .video-section{
        width: 100% !important;
    }
    .video-main-section{
        width: 100%;
    }
    .custom-order-4{
        order: 4;
    }
    .box-ul-column {
        flex-direction: inherit;
        flex-wrap: wrap;
    }

    .ido-box{

        margin-bottom: 30px;

    }

    .ido-box .btn-group-custom{

        flex-wrap: wrap;
        justify-content: center !important;
    
    }
    
    .ido-box .btn-group-custom a{
    
    margin-bottom: 10px !important;
    
    }

    .staking-dropdown{
    
        text-align: center;
        margin: 3rem 0;
    
    }

    .flex-xs-wrap{

        flex-wrap: wrap;
        justify-content: space-between;


    }

    .roadmap-item p{

        width: auto;

    }

    .conditions{

        flex-basis: 48%;
        margin: 0 0 20px 0!important;

    }

    .custom-table{

        margin-bottom: 40px;

    }
   
    .banner-video-section {
        height: 988px;

    }
  
}

@media (max-width:1024px) and (min-width:992px) {



    .roadmap-item p{

        width: auto;

    }

    .ido-box .btn-group-custom {
        flex-wrap: wrap;
        justify-content: center !important;
    }

    .ido-box .btn-group-custom a {
        margin-bottom: 10px !important;
    }
    .footer-social-icon i {
        font-size: 20px;
    }
    .custom-header .navbar-light .navbar-nav .nav-link {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .banner-video video{
        margin-bottom: 2rem !important;
    }
    .home-section-iframe{
        width: 100%;
        height: 525.5px;
        margin-bottom: 1rem;
    }

  

}

@media (max-width:1200px) and (min-width:1024px) {



    .roadmap-item p{

        width: auto;

    }

    .ido-box .btn-group-custom {
        flex-wrap: wrap;
        justify-content: center !important;
    }

    .ido-box .btn-group-custom a {
        margin-bottom: 10px !important;
    }
    .custom-col-width{
        width: 60% !important;
    }
    
}


@media (max-width:1440px) and (min-width:1200px) {



    .roadmap-item p{

        width: auto;

    }

    .ido-box .btn-group-custom {
        flex-wrap: wrap;
        justify-content: center !important;
    }

    .ido-box .btn-group-custom a {
        margin-bottom: 10px !important;
    }
}