/* #4F0574 */
/* --secondary: #1D1f27; */
/* 0048A8 */
:root {
    --primary: #1D1E20;
    --secondary: #1D1f27;
    --secondary1:#0048a8;
    --white:#fff;
    --black:#000000;
    --gray: gray;
    --overlay:#80808038;
    --soon:#DB7400;
    --dark-gray:#a6a6a6;

}
body{
    background: var(--primary) !important;
    color: var(--white) !important;
}

.f-bold{

    font-weight: 600;

}
.custom-header{
    background: var(--primary);
    box-shadow: 0px 4px 9px 3px rgba(255, 255, 255, 0.2) !important;
    position: sticky;
    top: 0;
    z-index: 10;
}
.custom-header .navbar-light .navbar-nav .nav-link{
    color: var(--white);
    font-size: 18px;
    font-weight: 500;
    padding-left: 2rem;
    padding-right: 2rem;
}
.custom-header .navbar-light .navbar-nav .nav-link:hover,.custom-header .navbar-light .navbar-nav .nav-link:focus{
    color: var(--gray);
}
.custom-header  .navbar-light .navbar-nav .nav-link.active{
    color: var(--white) !important;
}
.sale-section{
    /* border: 2px solid black; */
    width: 60%;
    padding: 10% 8%;
    background-color: var(--primary);
    border-radius: 5%;
}

.banner-section{
    height: calc(100vh - 87px);
    display: flex;
    justify-content: center;
    align-items: center;
   
}
.banner-video-background{
    position: absolute;
    z-index: -1;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 1200px;

}
.banner-video-section{
    display: flex;
    justify-content: center;
    align-items: center;
}
.home-section-iframe{
    width: 1065px;
    height: 600px;
    border-radius: 30px;
    margin: 2rem auto auto auto !important;
    display: flex;
}
.banner-background{
    position: absolute;
    z-index: -1;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: calc(100vh - 87px);
}
.banner-video{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
/* .home-play-icon{
    font-size: 40px;
    position: absolute;
} */
.banner-video video{
    width: 1000px;
    margin-top: 1rem;
    /* height: 50%; */

    border-radius: 30px;
}
.btn-custom{
border: 2.17888px solid #FFFFFF !important;

border-radius: 32.6831px !important;
padding: 10px 30px !important;
text-decoration: none;
}
.btn-custom:hover{
    color: var(--white);
}
.secondary-btn{
    /* background: var(--soon) !important;
    color: var(--white); */
    color: var(--white) !important;
    background-color:var(--soon);
    font-weight: 500;
    text-align: center;
    padding: 14px 50px;
    border: none;
    border-radius: 24px;
    font-size: 14px;
    line-height: 23px;
    transition: all 0.3s ease;
    text-decoration: none
}
.primary-btn{
    /* background: var(--primary);
    color: var(--white); */
    color: var(--white) !important;
    text-transform: uppercase;
    background-color:var(--secondary1);
    text-align: center;
    padding: 14px 50px;
    line-height: 23px;
    border: none;
    border-radius: 24px;
    font-size: 14px;
    transition: all 0.3s ease;
    text-decoration: none;
}

.logo{
    width: 200px;
}
.logo-text{
    font-size: 50px;
}

.slick-slide{
/* width: 480px !important; */
}

.banner-p{
    font-size: 30px;
    margin-top: 2.5rem;
}
.btn-group-custom{
    display: flex;
    align-items: center;
    margin-top: 3rem;
}
.btn-group-custom .btn-custom:nth-child(1){
    margin-right: 5rem;
}
.banner-img{
    width: 100%;
}
.feature-section{
    padding-top: 5rem;
    padding-bottom: 5rem;

}
.feature-ul{
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
}
.feature-ul li{
    padding: 1rem 0;
}
.feature-ul li a{
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 52px;
    color: var(--white);
    text-decoration: none;
    background-color: var(--secondary);
    padding: 0.5rem;
    border-radius: 10px;
    width: 458px;
}
.feature-ul li a span{
    margin-left: 1rem;
}
.feature-img{
    width: 400px;
}
.how-it-work-img{
    width: 500px;
}
.pad-section{
    background-color: var(--secondary);
    padding: 5rem 0;
}
.text-icon{
    border: 1.86615px solid var(--white);
    box-sizing: border-box;
    background: var(--primary);
    width: 50px;
    text-align: center;
    border-radius: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    margin: auto;
}
.text-box{
border: 0.933074px solid var(--white);
box-sizing: border-box;
border-radius: 37.323px;
padding: 2rem;
width: 300px !important;
height: 250px;
margin:2rem auto auto auto;
background-color: var(--primary);
}
.text-box p{
    font-size: 20px;
}
.pad-section .text-icon{
margin-top: 2rem;
}
.non-crypto-section{
    padding: 5rem 0;
}
.non-crypto-text{
    font-size: 35px;
    padding-bottom: 1rem;
}
.non-crypto-inner{
    padding-top: 3rem;
}
.second-background{
    position: absolute;
    z-index: -1;
    width: 100%;
    top: 0;
    bottom: 0;
}
.z-pad-section{
    padding-top: 18rem;
}
.z-pad-inner{
    padding-top: 7rem;
}
.box-ul-column{
    display: flex;
    flex-direction: column;
}
.box-ul-column .ul-box{
    /* margin-bottom: ; */
    margin: auto auto 1.5rem auto;
    width: 211px;
    height: 140px;
}
.ul-box{

    text-align: center;
    border: 1px dashed var(--white);
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ul-box-between{
    padding: 4rem;
    background-color: var(--secondary);
}
.ul-between-flex{
    display: flex;
    justify-content: space-between;
}
.ul-between-footer{
    text-align: center;
    margin-top: 13.5rem;
}
.box-ul-flex{
    display: flex;
}
.box-ul-flex .ul-box{
    width: 211px !important;
    height: 140px;
    margin-top: 1rem;
}
.box-ul-flex .ul-box:not(:last-child){
   
    margin-right: 1rem;
}
.zpad-tier-section{
    margin-top: 7rem;
}
.zpad-tier-text{
    text-align: center;
    font-size: 30px;
    margin-top: 1rem;
}
.tier-table-section{
    margin-top: 5rem;
    margin-bottom: 7rem;
}
.tier-table-section th{
    color: var(--white);
    border: 2px solid var(--white);
    text-align: center;
    font-size: 25px;
    padding: 1rem !important;

}
.tier-table-section td{
    color: var(--white);
    text-align: center;
    font-size: 25px;
    border: 2px solid var(--white) ;
}

.ido-box{

    border: 2px dashed var(--white);
    padding: 30px;
    background:  var(--secondary);
    border-radius: 30px;

}

.ido-small{

    padding: 10px;

}

.ido-box p{

    margin-bottom: 0 !important;

}

.live,.soon,.closed{

    font-weight: 600;
    text-align: right;

}

.live i{

    color: #2BED1A;
    font-size: 10px;
    margin-right: 5px;

}


.soon{

    color: var(--soon);
}


.soon i{

    color: var(--soon);
    font-size: 10px;
    margin-right: 5px;

}

.closed {

    color: #FF0000;
    

}
.closed i{

    color: #FF0000;
    font-size: 10px;
    margin-right: 5px;

}
.ido{

    display: flex;
    align-items: center;
    margin-bottom: 20px;

}

.ido-details{

    margin-left: 10px;

}

.ido-ul{

    list-style: none;
    padding: 0;
    display: flex;

}

.ido-ul li{

    margin-right: 10px;
    font-size: 12px;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--white);
    border-radius: 50%;
    background: rgb(29,30,32);
   
   
   }

.ido-ul a{

 color: var(--white);



}
.ido-ul a:hover{

    color: var(--white);
   
   
   
   }


.ido-cap{

    display: flex;
    justify-content: space-between;

}

.ido-slider-section{

    padding: 15rem 0px;

}
.apply-btn-section{
    display: flex;
    justify-content: space-evenly;
    padding-top: 14rem;
}
.apply-btn-section .btn-custom{
    font-size: 30px;
    padding: 0.5rem 3rem !important;
}
.third-background{
    position: absolute;
    width: 100%;
    z-index: -1;
    height: 600px;
}


/* changes */
.copyright{

    text-align: start;
    font-size: 13px;
    line-height: 15px;
    color:var(--dark-gray);
    padding: 14px 0;

}

.social{

    display: flex;
    justify-content: flex-end;
    align-items: center;
    list-style: none;
    padding: 0;

}

.social li{

    margin-right: 10px;

}

.social a{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    background: var(--white);
    line-height: 32px;
    color: var(--black);
    font-size: 17px;
    text-decoration: none !important;
    transition: all 0.3s ease;
}

.social a:hover{
    background: #00aced;
    color: var(--white);
}


/*  */
.footer{
    margin-top: 15rem;
    background-color: var(--primary);
}
.footer-heading{
    font-size: 40px;
}
.footer-social-icon{
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
    padding-right: 2rem;
}
.footer-social-icon a{
    color: var(--white);
}
.footer-social-icon a:hover{
    color: var(--gray);
}
.footer-social-icon i{
    font-size: 30px;
}
.footer-ul{
    display: flex;
    flex-direction: column;
    padding: 0;
    list-style: none;
}
.footer-ul li{
}
.footer-ul li a{
    text-decoration: none;
    font-size: 20px;
    line-height: 39px;
    color: var(--white);
}
.footer-ul li a:hover{
    color: var(--gray);
}
.sroll-top{
    background-color: var(--white);
    color: var(--secondary);
    padding: 1rem 1.2rem;
    border-radius: 50px;
    font-size: 30px;
    font-weight: bold !important;
    cursor: pointer;
}
.text-right{
    text-align: right;
}
.footer-right-text{
    display: flex;
    justify-content: right;
}
.h2{
    line-height: 91px;
    font-size: 50px !important;
}
.navbar-collapse.collapse.show .navbar-nav-scroll{
    /* max-height: 200px !important; */
}
.navbar-toggler .navbar-toggler-icon{
    background-color: var(--white);
}
.navbar-toggler{
    background-color: var(--white) !important;
}
.navbar-light .navbar-toggler {
    color: rgba(0,0,0,.55);
    border-color: var(--white) !important;
}
.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: none !important;
}
.ul-box p{
    font-size: 25px;
}
.ul-between-flex h5,.ul-between-footer h5{
font-size: 30px;
}
.video-section{
    /* background-image: url('../images/warrior.png'); */
    width: 488.81px;
height: 307.69px;
position: relative;
z-index: 1;
border-radius: 25.57px;
display: flex !important;
justify-content: center !important;
align-items: center !important;
cursor: pointer;

}
.video-section iframe{
    border-radius: 10px;
}
.video-section::after{
    /* content: ""; */
    background-color: var(--overlay) !important;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: inherit;
}
.play-icon{
font-size: 40px;
}
.video-body .modal-body{
    padding: 0 !important;
    margin: 0 !important;
}
.video-body .modal-content{
    background-color: transparent !important;
    border: none !important;
}
.video-main-section{
    width: 514.81px;
    text-align: center;
    margin: auto;
}
.video-main-section .h2{
    /* padding-top: 5rem;
    padding-bottom: 1rem; */
}
.video-main-section .banner-p{
    font-size: 22px;
}
.single-project-background{
    position: absolute;
    width: 100%;
    z-index: -1;
    height: 100%;
}
.single-project-section{
    height: 1000px !important;
}
.logo-text-img{
    width: 400px;
}
.form-control,.form-select{
    background: transparent !important;
    color: var(--white) !important;
}
.form-select option{
    background: var(--secondary) !important;
}
.form-control:focus,.form-select:focus{
    background: transparent !important;
    box-shadow: 1px 1px 1px 1px var(--secondary) !important;
}
.admin-projects{
    margin-top: 5rem;
}
.ido-box-footer{
    /* display: flex; */
}
.ido-box-footer .btn-custom:not(:first-child){
margin-left: 1rem !important;
}
.admin-form-padding{
    padding-top: 4rem;
}
.upload-img{
    width: 100px;
}

.staked{

    border-bottom: 2px dashed #ffffff60;
   

}

.staked{

    padding: 20px 0;

}

.staking-dropdown button{

    background-color: transparent !important;
    border:2px solid var(--white);
    border-radius: 32.6831px;
    padding: 10px 30px;

}

.staking-dropdown button:focus,.show>.btn-primary.dropdown-toggle{

    box-shadow: 1px 1px 13px 4px #8080805e !important;
    border-color: var(--white) !important;

}

.staking-dropdown button:hover{
    box-shadow: 1px 1px 13px 4px #8080805e !important;

    border-color: var(--white);

}


.roadmap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 70px 0 170px 0;
  }
  
  .roadmap-item {
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .roadmap-item p {
    margin-bottom: 0;
    position: absolute;
    top: 120%;
    width: 200px;
    font-weight: 600;
  }
  
  .roadmap-circle {
    border: 1px solid var(--black);
    border-radius: 50%;
    width: 70px;
    height: 70px;
    color: var(--secondary);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--gray);
  }

  .roadmap-circle img{

    width: 30px;

  }
  
  .roadmap-hr {
    border:2px dashed var(--white);
    background-color: transparent !important;
    width: 100px;
    height: 2px !important;
    margin: 0 20px;
    opacity: 1 !important;
  }
  
  .circle-active .roadmap-circle {
    background-color: var(--soon);
    color: var(--primary);
  }

  .circle-active p{

    color: var(--soon);

  }


.conditions{

    background-color: var(--secondary);
    padding: 30px;
    border-radius: 30px;
    justify-content:space-between;
    width: 25%;


}


.conditions:not(:last-child){

    margin-right: 20px;

}

.conditions h4{

    font-weight: 400;
    font-size: 18px;
    margin-bottom: 1rem;

}

.custom-checkbox{

    text-align: center;
    font-size: 25px;

}

.custom-checkbox input{

    width:18px;
    height:18px;
    margin-right: 10px;

}

.conditions-met{

    display: flex;
    align-items: flex-start;
    justify-content: space-between;


}

.tick-enable{

    border-radius: 50%;
    border: 2px solid var(--soon);
    width:30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.tick-enable i{
    
    font-size: 15px;

}

.tick-disble i{
    
    display: none;

}

.unstaking-warn{

    display: flex;
    align-items: center;
    margin: 3rem 1rem;

}

.unstaking-warn p{

   
    font-size: 20px;
    line-height: 32px;
    line-height: 50px;
    margin-left: 20px;

}


.custom-table{

    border: 2px dashed var(--white);
    border-radius: 30px;

}

.table-dashed {
    border-collapse: separate;
    border-spacing: 0;
    
   
  }


  .table-dashed tr th,
  .table-dashed tr td {
   
    padding: 20px 25px;
    color: var(--white);
    font-weight: 500;
    font-size: 18px;
    
  
  }

  
  .table-dashed tr td {

    border-bottom: 2px solid var(--white);
  
  }

  


.table-dashed tr td:last-child
{
 
    text-align: end;
    
}
  .table-dashed tr th {
    text-align: left;
  }

  
.max-staked{

    position: relative;

}

.max-staked button{

    background-color: transparent !important;
    border: none;
    position: absolute;
    top: 0;
    right: 0;

}

.max-staked button:focus{

        box-shadow:none !important;
        border:none !important;

}


.mdb-datatable-length.bs-select{
    display: none;
}
.sorting_desc,.sorting_asc,.sorting{
    color: var(--white);
}
td{
    color: var(--white);
}
.ml-1{
    margin-left: 1rem;
}
.modal-content{
    background-color: var(--secondary) !important;
    border: 2px dashed var(--white) !important;
    border-radius: 30px !important;
}
.modal-title.h4{
    color: var(--white) !important;
}
.dropdown-menu{
    background: var(--secondary) !important;
}
.dropdown-item{
    color: var(--white) !important;
}
.dropdown-item:focus,.dropdown-item:hover{
background-color: var(--secondary) !important;
color: var(--gray) !important;
}
.dropdown-item.active, .dropdown-item:active{
background-color: var(--secondary) !important;

}
.anchor{
    color: var(--white);
    text-decoration: none;
}
.anchor:hover{
    color: var(--white);
}
.left-none{
    border-left: none !important;
}
.top-none{
    border-top: none !important;
}
.right-none{
    border-right: none !important;
}
.bottom-none{
    border-bottom: none !important;
}
.tier-table-section{
    background-color: var(--secondary);
    border: 2px solid var(--white);
    border-radius: 25px;
}
.tier-table-section table{
    margin-bottom: 0px;
}
.no-border-bottom th{
    border-bottom: none;
}



/* ///video */
.video {
    width: 100vw;
    height: 100vh;
  }
  
  /* Controls */
  
  .controlsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    background-color: transparent;
    margin-top: -50vw;
    padding: 0 40px;
    z-index: 20;
  }
  
  .controls {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding-top: 18rem;
    margin: auto;
  }
  
  .controlsIcon {
    width: 40px;
    height: 40px;
    cursor: pointer;
    margin-left: 10rem;
    margin-right: 10rem;
  }
  
  .controlsIcon--small {
    width: 32px;
    height: 32px;
    cursor: pointer;
    margin-left: 10rem;
    margin-right: 10rem;
  }
  
  /* The time controls section */
  
  .timecontrols {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: absolute;
    bottom: 4rem;
    margin-left: 10vw;
  }
  
  .time_progressbarContainer {
    background-color: gray;
    border-radius: 15px;
    width: 75vw;
    height: 5px;
    z-index: 30;
    position: relative;
    margin: 0 20px;
  }
  
  .time_progressBar {
    border-radius: 15px;
    background-color: indigo;
    height: 100%;
  }
  
  .controlsTime {
    color: white;
  }
  .text-ab{
    position: absolute;
    right: 10px;
    top: 39px;
  }
  input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.btn-custom:hover,.btn-custom:active,.btn-custom:focus{
    box-shadow: 1px 1px 13px 4px #8080805e;
}

.text-red{
    color: red;
}
.loading-modal .modal-content{
    width: 200px !important;
    margin: auto;
}
.how-it-text{
    font-size: 25px;
}
.how-its-work{
    padding-top: 5rem;
}
.how-its-work .h2{
    margin-bottom: 3rem;
}
.custom-font{
    font-size: 18px;
}
.modal-hci-text p{
margin-bottom: 3px;
}
.open-video-hci{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.open-video-hci .background{
    position:absolute;
    font-size: 55px;
    color: var(--primary);
    cursor: pointer;
    background: var(--white);
    width: 80px;
    height: 77px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    border: 2px solid var(--secondary);
    box-shadow: 1px -1px 20px 17px #dad9db;
}
.hci-iframe{
    width: 100%;
    height: 300px;
}
.closer-icon{
    font-size: 20px;
    cursor: pointer;
}
.image-card img{
    width: 160px;
    height: 156px;
    overflow: auto;
    border-radius: 20px;
    transition: all 1s;

}
.image-card{
    width: 160px;
    box-shadow: 1px 1px 1px 1px rgb(139, 139, 139);
    border-radius: 20px;
    box-shadow: 1px 1px 8px 1px rgb(118 116 116 / 63%);
    overflow: hidden;
    cursor: pointer;
}
.image-card img:hover{
    transform: scale(1.1);
}
.b2b-section-height .single-project-section{
    height: 1300px !important;
}
.text{
    position: absolute;
    z-index: 111111;
    left: 0;
    right: 0;
}
.custom-modal{
    z-index: 999999 !important;
}

.token-description{
    font-size: 25px;
    margin: 1.5rem 0;
}
.pub-sale-footer{
    margin-top: 1.5rem;
}
.pub-sale-image{
    width: 500px;
    max-width: 100%;
    margin: auto;
}
.min-max {
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-left: 29%; */
}
.increament {
    padding: 15px 12px;
    background: #f3f4f7;
    border-radius: 10px;
    margin-bottom: 1rem;
    margin-top: 1rem;
    width: 100px;

    display: flex;
    align-items: center;
  }
.value-button {
display: inline-block;
color: var(--black);
font-size: 25px;
line-height: 0;
margin: 0px;
height: 4px;
text-align: center;
vertical-align: middle;
background: transparent;
-webkit-touch-callout: none;
-webkit-user-select: none;
-khtml-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
cursor: pointer;
}
input.number {
    
}
.min-max input.number{

outline: none;
color: var(--black);
flex-basis: 30%;
text-align: center;
border: none;
margin: 0px;
width: 50px;
background-color: transparent;
font-size: 20px;

}

  
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
-webkit-appearance: none;
margin: 0;
}
